export const birthdayModel = {
    displayName: 'display_name',
    birthday: 'birthday',
    avatar: 'avatar',
    images: {
        fileName: 'filename',
        typeImage: 'type_image',
    },
};

export const newsModel = {
    title: 'title',
    slug: 'slug',
    category: {
        slug: 'slug',
    },
    images: {
        full: Object,
    },
    user: {
        displayName: 'display_name',
        position: 'position',
    },
};
