import { call, put, takeLatest, select } from 'redux-saga/effects';

import { pickArrayUniversalModelValues, timeout } from 'utils';
import actions, { constants } from './actions';
import { getAllNews, getAllPersons } from './selectors';
import { default as endpoints } from './endpoints';
import { birthdayModel, newsModel } from './models';
import history from './router/history';
import { paths } from './router/constants';
import { GALLERY_SLIDE_TIMEOUT, NEWS_SLIDE_TIMEOUT } from './config/constants';

function* fetchData(selector, request, model, action) {
    try {
        const localData = yield select(selector);
        const rawServerData = yield call(request);
        const serverData = pickArrayUniversalModelValues(rawServerData, model);
        const localDataHash = JSON.stringify(localData);
        const serverDataHash = JSON.stringify(serverData);

        if (localDataHash !== serverDataHash) {
            yield put({ type: action, payload: serverData });
        }
    } catch (error) {
        console.log(error);
        yield put({
            type: constants.UPDATING_DATA_FAILED,
            message: error.message,
        });
    }
}

function* nextPage() {
    const currentPath = history.location.pathname;
    const nextPath =
        currentPath !== `${paths.BIRTHDAY_GALLERY}`
            ? paths.BIRTHDAY_GALLERY
            : paths.NEWS;

    switch (nextPath) {
        case paths.BIRTHDAY_GALLERY:
            yield call(navigateToGallery);
            break;
        case paths.NEWS:
            yield call(navigateToNews);
            break;
        default:
            throw new Error('unknown route');
    }

    const persons = yield select(getAllPersons);
    const timeoutForNextPage =
        nextPath === paths.BIRTHDAY_GALLERY && persons.length
            ? GALLERY_SLIDE_TIMEOUT
            : NEWS_SLIDE_TIMEOUT;
    yield call(timeout, timeoutForNextPage);
    yield put(actions.nextPage());
}

function* navigateToGallery() {
    yield call(
        fetchData,
        getAllPersons,
        endpoints.fetchBirthdays,
        birthdayModel,
        constants.UPDATING_BIRTHDAYS_SUCCESS
    );
    const persons = yield select(getAllPersons);

    yield put(actions.nextNews());

    if (persons.length) {
        yield call(history.push, paths.BIRTHDAY_GALLERY);
    } else {
        yield call(navigateToNews);
    }
}

function* navigateToNews() {
    yield call(
        fetchData,
        getAllNews,
        endpoints.fetchNews,
        newsModel,
        constants.UPDATING_NEWS_SUCCESS
    );

    yield put(actions.nextPerson());

    yield call(history.push, paths.NEWS);
}

function* saga() {
    yield takeLatest(constants.NEXT_PAGE, nextPage);
}

export default saga;
